import React, { useState } from "react";
import styles from "./header.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Header() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loader

  const validateEmail = (email) => {
    // Simple regex for basic email validation
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setErrorMessage(""); // Clear previous error message

    // Validation checks
    if (!email.trim()) {
      setErrorMessage("Email field cannot be blank.");
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    try {
      setIsLoading(true); // Show loader
      const response = await fetch(
        "https://create-com-backend.vercel.app/sendData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        toast.success("Request submitted successfully!"); // Show success message as a toast
        setEmail(""); // Clear the input field after successful submission
      } else {
        const errorText = await response.text();
        // Display error message using toast
        toast.error(errorText);
      }
    } catch (error) {
      console.error("Failed to submit the request:", error);
      setErrorMessage("Error submitting request. Please try again."); // Set a generic error message
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className={`${styles.container} ${isLoading ? styles.loading : ""}`}>
      {/* Show loader when isLoading is true */}
      {isLoading && <div className={styles.loader}></div>}

      <div className={styles.left_container}>
        <h2 className={styles.heading}>
          <span style={{ fontSize: "35px" }}>
            Decentralized Revolution With
          </span>
          <span style={{ color: "#03B56A" }}> Creative Objective Models </span>
        </h2>
        <p className={styles.sub_heading}>
          A new file type to Accelerate Human Creativity and Intelligence for
          the Post AI Universe
        </p>
        <form className={styles.input_container} onSubmit={handleSubmit}>
          <input
            type="email" // This helps with native HTML5 email validation
            className={styles.input_field}
            placeholder="Request Early Access"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required // This enforces a non-empty field
          />
          <button type="submit" className={styles.submit_button}>
            Submit
          </button>
        </form>
        {errorMessage && (
          toast.error(errorMessage)
          // <div className={styles.error_message}>{errorMessage}</div>
        )}
      </div>
      <ToastContainer /> {/* Render ToastContainer at the root level of your component */}
    </div>
  );
}
