import React, { useState } from "react";
import styles from "./COM.module.css";
// import comImage from '../../Assets/architectureMain.svg';
import comImage from "../../Assets/Group 57.svg";

const COMComponent = () => {
  const [hoverText, setHoverText] = useState("");

  const hoverAreas = {
    area1: "Text for Area 1",
    area2: "Text for Area 2",
    // Add more areas as needed
  };

  return (
    <div className={styles.container}>
      <h2>
        <span className={styles.whiteText}>What is</span>{" "}
        <span className={styles.greenText}>Create Protocol</span>
        <span className={styles.whiteText}>?</span>
      </h2>
      <p className={styles.desc}>
        De AI Network For Every Human, <br></br>
        Which Makes A New Class Of Blockchain+AI cDapps <br></br>
        Paving The Way For A Limitless Economy.
      </p>
      <div className={styles.imageContainer}>
        <img
          src={comImage}
          alt="Creative Objective Models"
          className={styles.comImage}
        />
        <div
          className={styles.topArea}
          onMouseEnter={() => setHoverText("Top side of image")}
          onMouseLeave={() => setHoverText("")}
        />
        <div
          className={styles.bottomArea}
          onMouseEnter={() => setHoverText("Bottom side of image")}
          onMouseLeave={() => setHoverText("")}
        />
        <div
          className={styles.leftArea}
          onMouseEnter={() => setHoverText("Left side of image")}
          onMouseLeave={() => setHoverText("")}
        />
        <div
          className={styles.rightArea}
          onMouseEnter={() => setHoverText("Right side of image")}
          onMouseLeave={() => setHoverText("")}
        />
        {/* {hoverText && <div className={styles.hoverText}>{hoverText}</div>} */}
      </div>
    </div>
  );
};

export default COMComponent;
