import React, { useState } from "react";
import styles from "./Navbar.module.css";
import Xicon from "../../Assets/x.svg";
import logoimage from "../../Assets/logosvg.svg";
import GithubImage from "../../Assets/github.svg";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.navbar_container}>
      <div className={styles.navbar}>
        <div className={styles.navbar_logo}>
          <img src={logoimage} alt="Logo" className={styles.logo_image} />
          <div className={styles.create_text}>CREATE PROTOCOL</div>{" "}
          {/* Corrected class name */}
        </div>

        <nav role="navigation" className={styles.hamburger}>
          <div className={styles.menuToggle}>
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>

            <ul className={styles.menu}>
              {/* <a href="#">
                <li>About</li>
              </a>
              <a href="#">
                <li>Research</li>
              </a>
              <a href="#">
                <li>Updates</li>
              </a>
              <a href="#">
                <li>Teams</li>
              </a> */}
              <a
                href="https://github.com/create-protocol"
                target="_blank"
              >
                <li>Research</li>
              </a>
              <a href="https://twitter.com/CreateProtocol" target="_blank">
                <li>Community</li>
              </a>
              <a href="mailto:info@createprotocol.org" target="_blank">
                <li>Contact</li>
              </a>
            </ul>
          </div>
        </nav>
        <div
          className={`${styles.navbar_links_container} ${
            isMenuOpen ? styles.menu_open : ""
          }`}
        >
          {/* <a href="/about" className={styles.navbar_link}>
            About
          </a>
          <a href="/research" className={styles.navbar_link}>
            Research
          </a>
          <a href="/updates" className={styles.navbar_link}>
            Updates
          </a>
          <a href="/teams" className={styles.navbar_link}>
            Teams
          </a> */}
          <a
            href="https://github.com/create-protocol"
            target="_blank"
            className={styles.navbar_link}
          >
            Research
          </a>
          <a
            href="https://twitter.com/CreateProtocol"
            target="_blank"
            className={styles.navbar_link}
          >
            Community
          </a>
          <a
            href="mailto:info@createprotocol.org"
            target="_blank"
            className={styles.navbar_link}
          >
            Contact
          </a>
        </div>
        <div className={styles.navbar_actions}>
          {/* <a href="/docs" className={styles.navbar_docs_btn}>
            Docs
          </a> */}
          <a href="https://twitter.com/CreateProtocol" target="_blank">
            <img src={Xicon} alt="X Icon" />
          </a>
          <a
            href="https://github.com/create-protocol"
            target="_blank"
          >
            <img src={GithubImage} alt="Github Icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
