import React from "react";
import styles from "./youTube.module.css";

const YoutubeVideo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <h2 className={styles.imageheading}>
          <span style={{ color: "#029e57",fontWeight:"bold" }}>Why</span>
          <span style={{ color: "white" }}> Creative Objective Models?</span>
        </h2>
        
      </div>
      <div className={styles.rightSection}>
        <iframe
          src="https://www.youtube.com/embed/q2pqwUmyA2M"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default YoutubeVideo;
