import React from "react";
import styles from "./footer.module.css";
import logoimage from "../../Assets/logosvg.svg";
import Xicon from "../../Assets/x.svg";
import GithubImage from "../../Assets/github.svg";

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.container1}>
        <div className={styles.logo}>
          <img src={logoimage} alt="create_logo" />
          CREATE PROTOCOL
        </div>
        <p className={styles.desc}>
          Built By Creators, For Creators. <br></br>Our Decentralized AI Platform
          Pioneers The Creative Objective Model Transformation
        </p>
        <div className={styles.social_icons}>
          <a href="https://twitter.com/CreateProtocol" target="_blank">
            <img src={Xicon} alt="X Icon" />
          </a>
          <a
            href="https://github.com/create-protocol"
            target="_blank"
          >
            <img src={GithubImage} alt="Github Icon" />
          </a>
          {/* <img src={Xicon} alt="X Icon" />
          <img src={discord} alt="X Icon" />
          <img src={telegram} alt="X Icon" />
          <img src={reddit} alt="X Icon" /> */}
        </div>
      </div>
      <div className={styles.container2}>
        {/* <div>
          <h3>Developers</h3>
          <p>Docs</p>
        </div>
        <div>
          <h3>Company</h3>
          <p>Blog</p>
          <p>Create Ecosystem</p>
        </div>
        <div>
          <h3>Legal</h3>
          <p>Privacy Policy</p>
          <p>Terms of Service</p>
        </div> */}
        <div>
          <h3>Contact</h3>
          <a href="mailto:info@createprotocol.org" className={styles.links}>
            <p>info@createprotocol.org</p>
          </a>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Footer;
