import React from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/footer/footer";
import COMComponent from "./components/COM";
import YoutubeVideo from "./components/YoutubeVideo/YoutubeVideo";
import "./index.css";

function App() {
  return (
    <div
      className="App"
    >
      <Navbar />
      <Header />
      <YoutubeVideo/>
      <COMComponent />
      <Footer />
    </div>
  );
}

export default App;
